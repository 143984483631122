<template>
  <div class="about">
    <header-img
      :bgImg="require('@/assets/img/product/20191012170133067.jpg')"
    ></header-img>
    <div class="about-box">
      <item-contorl
        :item="_item"
        :titleActive="titleActive"
        :toRoute="_thisActive"
      ></item-contorl>
      <!-- <div class="title">
        <div class="title-box">
          <div class="product-title">—产品中心</div>
          <div class="title-flex" ref="activeClick" @click="activeClick">
            <div data-index="1" :class="{ active: this.titleActive == 1 }">
              <span>裸眼3D显示屏</span>
            </div>
            <div data-index="2" :class="{ active: this.titleActive == 2 }">
              <span>裸眼3D拼接屏</span>
            </div>
            <div data-index="3" :class="{ active: this.titleActive == 3 }">
              <span>裸眼3D智能膜</span>
            </div>
            <div data-index="4" :class="{ active: this.titleActive == 4 }">
              <span>裸眼3D眼镜</span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="detail-box">
        <el-row class="detail-container" v-if="productDataList[titleActive-1].length" >
          <el-col  v-for="item in productDataList[titleActive-1]" :key="item.id" :xs="item.span.xs" :sm="item.span.sm">
            <div class="detail-more">
              <div class="detail-more-img">
                <img :src="item.img" alt="" />
              </div>
              <div class="yichu1">
                <h5 v-html="item.title"></h5>
                <span v-html="item.detail"
                  ></span
                >
              </div>
              <div class="hover-more">
                <i>MORE</i>
              </div>
            </div>
          </el-col>
        </el-row>
        <div v-else>暂无数据</div>
      </div> -->
      <!-- <Product :titleActive="titleActive"></Product> -->
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
import Product from "../components/product/product.vue";
import ItemContorl from "../components/CommonConponent/itemContorl.vue";
export default {
  name: "product",
  components: {
    headerImg,
    Product,
    ItemContorl,
  },
  data() {
    return {
      thisRoute: "/product/index",
      thisRoute2:'/product/software',
      //去url的id值，或默认为 1
      titleActive: this.$route.query.id || 1,
      _item:'',
      _thisActive:'',
      item: {
        title: "Product",
        list: [
          { index: "1", detail: "Glasses-Free 3D Display" },
          { index: "2", detail: "3D Video Wall" },
          { index: "3", detail: "Glasses-Free 3D screen" },
          { index: "4", detail: "Reality Player 2.0" },
        ],
      },
      item2: {
        title: "Software",
        list: [
              {
                index: "1",
                detail: this.$t("product.unityPlay"),
                query: { id: 1 },
              },
              {
                index: "2",
                detail: this.$t("product.DD"),
                query: { id: 2 },
              },
              {
                index: "3",
                detail: this.$t("product.Visyal3D"),
                query: { id: 3 },
              },
              {
                index: "4",
                detail: this.$t("product.VisualHealthCloud"),
                query: { id: 4 },
              }, 
        ],
      },
      mun: 12,
      productDataList: [
        [
          {
            id: 1,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 2,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 3,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 12 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 4,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 5,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 6,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 12 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
        ],
        [],
        [],
        [],
      ],
    };
  },
  methods: {
    // activeClick(e) {
    //   //切换，index
    //   this.titleActive = e.target.parentElement.dataset.index;
    //   this.$router.push({path:this.thisRoute+'?id='+this.titleActive})
    // },
    fromRouter(router){
      if (router=='/product/software') {
      this._item = this.item2
      this._thisActive = this.thisRoute2
    }else{
      this._item = this.item
      this._thisActive = this.thisRoute
    }
    }
  },
  beforeMount() {
    this.fromRouter(this.$route.path)
  },
  watch: {
    $route(to, from) {
      this.fromRouter(to.path)
      this.titleActive = to.query.id;
    },
  },
};
</script>
<style lang="less" scoped>
.about-box {
  min-height: 300px;

  .detail-box {
    padding: 3% 0;
    .detail-container {
      // padding: 0 2%;
      max-width: 1400px;
      margin: 0 auto;
      > div {
        // margin-bottom: 30px;
        height: 427.6px;
        display: block;
        padding: 15px;
        height: 100%;
      }

      .detail-more {
        position: relative;
        background: #f8f8f8;
        padding: 15px;
        height: 100%;
        text-align: left;
        .hover-more {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 104, 183, 0.9);
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          i {
            color: #fff;
            position: absolute;
            width: 100%;
            background: url("~@/assets/img/product/Floor002048-detail.png")
              no-repeat center top;
            padding-top: 40px;
            line-height: 20px;
            top: 50%;
            margin-top: -30px;
            text-align: center;
            font-style: normal;
          }
        }
        .detail-more-img {
          width: 100%;
          height: 334px;
          margin-bottom: 10px;
          background-size: contain;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      h5 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        color: #333;
        display: block;
      }
      span {
        color: #8a8fa0;
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
}
</style>
